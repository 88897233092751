import { API } from 'src/helpers/api';
import { ActionType, ActionTypes } from 'src/contexts/AppContext';

export const getCards = async (activityId: string, dispatch: React.Dispatch<ActionType>) => {
  try {
    const { data } = await API({
      url: 'card/getAllCards', method: 'GET', params: {
        smbActivityId: activityId,
      },
    });
    dispatch({ type: ActionTypes.GET_CARDS, payload: data });
  } catch (e) {
    dispatch({ type: ActionTypes.ERROR_MESSAGE, payload: e.message });
  }
};
