'use client';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export function AuthConsumer({ children }: Props) {
  return '';
  // return (
  //   <AuthContext.Consumer>
  //     {(auth) => (auth.loading ? <SplashScreen /> : children)}
  //   </AuthContext.Consumer>
  // );
}
