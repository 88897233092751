import { API } from 'src/helpers/api';
import { BOT_ID } from 'src/config-global';
import { ActionType, ActionTypes } from 'src/contexts/AppContext';

export const getAllUsers = async (dispatch: React.Dispatch<ActionType>) => {
  try {
    const { data } = await API({
      url: 'sso/user/all',
      method: 'GET',
      params: { botId: BOT_ID }
    });
    if (dispatch) {
      dispatch({
        type: ActionTypes.GET_ALL_USERS,
        payload: { users: data },
      });
    }
    return data.payload;
  } catch (e) {
    console.error(e);
    return null;
  }
};
