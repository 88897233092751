'use client';

import { useMemo } from 'react';
import merge from 'lodash/merge';

import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeOptions, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';

// import { endpoints } from 'src/utils/b24-axios';

import { useLocales } from 'src/locales';

import { useSettingsContext } from 'src/components/settings';
import { useContentContext } from 'src/components/content/context/content-context';

import { colorsType } from 'src/sections/generate-page/theme/palette';

import { BlockType, BlockTypes, SectionTypes } from 'src/types/generator';

// system
import { palette } from './palette';
import { shadows } from './shadows';
import typography from './typography';
// options
import RTL from './options/right-to-left';
import { customShadows } from './custom-shadows';
import { componentsOverrides } from './overrides';
import { createPresets } from './options/presets';
import { createContrast } from './options/contrast';
import NextAppDirEmotionCacheProvider from './next-emotion-cache';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function ThemeProvider({ children }: Props) {
  const { currentLang } = useLocales();

  const { sections } = useContentContext();

  const settings = useSettingsContext();

  const presets = createPresets(settings.themeColorPresets);

  const contrast = createContrast(settings.themeContrast, settings.themeMode);

  const themeSection = useMemo(() => {
    if (sections) {
      return sections.find((section) => section.sectionType === SectionTypes.themeSettings);
    }
    return null;
  }, [sections]);

  const rootColors = palette(settings.themeMode);

  const colors = useMemo(() => {
    const { primary, secondary } = rootColors;
    const colorsFromRoot: colorsType = {
      primary,
      secondary,
    };
    if (rootColors && themeSection) {
      const colorsBlock = themeSection.data.main.find(
        (block: BlockType) => block.type === BlockTypes.themeSettings
      );
      if (colorsBlock) {
        const updatedPalette = assignColors(colorsBlock, colorsFromRoot);
        return updatedPalette;
      }
    }
    return colorsFromRoot;
  }, [themeSection, rootColors]);

  const fontFamily = useMemo(() => {
    if (themeSection && themeSection.data.text) {
      const foundFont = themeSection.data.text.find(
        (block: BlockType) => block.type === BlockTypes.fontSettings
      )?.googleFontFamily;
      if (foundFont) return foundFont;
    }
    return 'roboto';
  }, [themeSection]);

  const customCSS = useMemo(() => {
    if (themeSection && themeSection.data.css) {
      const result = themeSection.data.css.find(
        (block: BlockType) => block.type === BlockTypes.css
      );
      if (result) {
        return result['custom-css'];
      }
    }
    return '';
  }, [themeSection]);

  const memoizedValue = useMemo(
    () => ({
      palette: {
        ...colors,
        ...presets.palette,
        ...contrast.palette,
      },
      customShadows: {
        ...customShadows(settings.themeMode),
        ...presets.customShadows,
      },
      direction: settings.themeDirection,
      shadows: shadows(settings.themeMode),
      shape: { borderRadius: 8 },
      typography: typography(fontFamily as string),
    }),
    [
      settings.themeMode,
      settings.themeDirection,
      presets.palette,
      presets.customShadows,
      contrast.palette,
      fontFamily,
      colors,
    ]
  );

  const theme = useMemo(() => createTheme(memoizedValue as ThemeOptions), [memoizedValue]);

  theme.components = merge(componentsOverrides(theme), contrast.components);

  const themeWithLocale = useMemo(
    () => createTheme(theme, currentLang.systemValue),
    [currentLang.systemValue, theme]
  );

  return (
    <NextAppDirEmotionCacheProvider options={{ key: 'css' }}>
      <MuiThemeProvider theme={themeWithLocale}>
        <RTL themeDirection={settings.themeDirection}>
          {customCSS && <style>{customCSS as string}</style>}
          <CssBaseline />
          {children}
        </RTL>
      </MuiThemeProvider>
    </NextAppDirEmotionCacheProvider>
  );
}

function assignColors(colorsBlock: BlockType, destinationPalette: colorsType) {
  const updatedDestinationPalette = { ...destinationPalette };
  if (colorsBlock) {
    if (colorsBlock.primaryMainColor)
      updatedDestinationPalette.primary.main = colorsBlock.primaryMainColor as string;
    if (colorsBlock.primaryDarkColor)
      updatedDestinationPalette.primary.dark = colorsBlock.primaryDarkColor as string;
    if (colorsBlock.primaryDarkerColor)
      updatedDestinationPalette.primary.darker = colorsBlock.primaryDarkerColor as string;
    if (colorsBlock.primaryLightColor)
      updatedDestinationPalette.primary.light = colorsBlock.primaryLightColor as string;
    if (colorsBlock.primaryLighterColor)
      updatedDestinationPalette.primary.lighter = colorsBlock.primaryLighterColor as string;
    if (colorsBlock.primaryContrastText)
      updatedDestinationPalette.primary.contrastText = colorsBlock.primaryContrastText as string;
    if (colorsBlock.secondaryMainColor)
      updatedDestinationPalette.secondary.main = colorsBlock.secondaryMainColor as string;
    if (colorsBlock.secondaryDarkColor)
      updatedDestinationPalette.secondary.dark = colorsBlock.secondaryDarkColor as string;
    if (colorsBlock.secondaryDarkerColor)
      updatedDestinationPalette.secondary.darker = colorsBlock.secondaryDarkerColor as string;
    if (colorsBlock.secondaryLightColor)
      updatedDestinationPalette.secondary.light = colorsBlock.secondaryLightColor as string;
    if (colorsBlock.secondaryLighterColor)
      updatedDestinationPalette.secondary.lighter = colorsBlock.secondaryLighterColor as string;
    if (colorsBlock.secondaryContrastText)
      updatedDestinationPalette.secondary.contrastText =
        colorsBlock.secondaryContrastText as string;
  }
  return updatedDestinationPalette;
}
