import { NextFont } from '@next/font';
import localFont from 'next/font/local';
import {
  // Oswald,
  // Ubuntu,
  // Raleway,
  // Barlow,
  Roboto,
  // Open_Sans,
  // Montserrat,
  // Public_Sans,
  // Poppins,
  Comfortaa,
} from 'next/font/google';

// ----------------------------------------------------------------------

export function remToPx(value: string) {
  return Math.round(parseFloat(value) * 16);
}

export function pxToRem(value: number) {
  return `${value / 16}rem`;
}

export function responsiveFontSizes({ sm, md, lg }: { sm: number; md: number; lg: number }) {
  return {
    '@media (min-width:600px)': {
      fontSize: pxToRem(sm),
    },
    '@media (min-width:900px)': {
      fontSize: pxToRem(md),
    },
    '@media (min-width:1200px)': {
      fontSize: pxToRem(lg),
    },
  };
}

declare module '@mui/material/styles' {
  interface TypographyVariants {
    fontSecondaryFamily: React.CSSProperties['fontFamily'];
    fontWeightSemiBold: React.CSSProperties['fontWeight'];
  }
}

export const comfortaa = Comfortaa({
  weight: ['400', '500', '600', '700'],
  subsets: ['latin'],
  display: 'swap',
  fallback: ['Helvetica', 'Arial', 'sans-serif'],
});

// export const public_sans = Public_Sans({
//   weight: ['400', '500', '600', '700', '800', '900'],
//   subsets: ['latin'],
//   display: 'swap',
//   fallback: ['Helvetica', 'Arial', 'sans-serif'],
// });

// export const barlow = Barlow({
//   weight: ['400', '500', '600', '700', '800', '900'],
//   subsets: ['latin'],
//   display: 'swap',
//   fallback: ['Helvetica', 'Arial', 'sans-serif'],
// });

export const roboto = Roboto({
  weight: ['300', '400', '500', '700', '900', '100'],
  subsets: ['latin'],
  display: 'swap',
  fallback: ['Helvetica', 'Arial', 'sans-serif'],
});

const almoni = localFont({
  src: [
    {
      path: './fonts/almoni/almoni-light-aaa.otf',
      weight: '300',
      style: 'normal',
    },
    {
      path: './fonts/almoni/almoni-regular-aaa.otf',
      weight: '400',
      style: 'normal',
    },
  ],
});

const gegoe = localFont({
  src: [
    {
      path: './fonts/gegoe/Segoe UI.ttf',
      weight: '300',
      style: 'normal',
    },
    {
      path: './fonts/gegoe/Segoe UI Bold.ttf',
      weight: '400',
      style: 'normal',
    },
  ],
});

// export const montserrat = Montserrat({
//   weight: ['300', '400', '500', '700', '900', '100'],
//   subsets: ['latin'],
//   display: 'swap',
//   fallback: ['Helvetica', 'Arial', 'sans-serif'],
// });

// export const raleway = Raleway({
//   weight: ['300', '400', '500', '600', '700', '900', '100'],
//   subsets: ['latin'],
//   display: 'swap',
//   fallback: ['Helvetica', 'Arial', 'sans-serif'],
// });

// export const opensans = Open_Sans({
//   weight: ['300', '400', '600', '500', '700', '800'],
//   subsets: ['latin', 'hebrew'],
//   display: 'swap',
//   fallback: ['Helvetica', 'Arial', 'sans-serif'],
// });

// export const playfair_display = Playfair_Display({
//   weight: ['400', '500', '600', '700', '800'],
//   subsets: ['latin'],
//   display: 'swap',
//   fallback: ['Helvetica', 'Arial', 'sans-serif'],
// });

// export const lato = Lato({
//   weight: ['100', '400', '700', '900'],
//   subsets: ['latin'],
//   display: 'swap',
//   fallback: ['Helvetica', 'Arial', 'sans-serif'],
// });

// export const poppins = Poppins({
//   weight: ['100', '200', '300', '400', '500', '600', '700', '800'],
//   subsets: ['latin'],
//   display: 'swap',
//   fallback: ['Helvetica', 'Arial', 'sans-serif'],
// });

// export const oswald = Oswald({
//   weight: ['200', '300', '400', '500', '600', '700'],
//   subsets: ['latin'],
//   display: 'swap',
//   fallback: ['Helvetica', 'Arial', 'sans-serif'],
// });

// export const ubuntu = Ubuntu({
//   weight: ['300', '400', '500', '700'],
//   subsets: ['latin'],
//   display: 'swap',
//   fallback: ['Helvetica', 'Arial', 'sans-serif'],
// });

const fontsSubsets: Record<string, NextFont> = {
  // barlow,
  roboto,
  comfortaa,
  almoni,
  gegoe
  // public_sans,
  // montserrat,
  // raleway,
  // opensans,
  // playfair_display,
  // lato,
  // poppins,
  // oswald,
  // ubuntu,
};

// export const secondaryFont = Comfortaa({
//   weight: ['700'],
//   subsets: ['latin'],
//   display: 'swap',
//   fallback: ['Helvetica', 'Arial', 'sans-serif'],
// });

// ----------------------------------------------------------------------

// LEARN MORE
// https://nextjs.org/docs/basic-features/font-optimization#google-fonts

const typography = (fontSubset: string) =>
  ({
    fontFamily: fontsSubsets[fontSubset]?.style?.fontFamily,
    fontWeightRegular: 400,
    fontWeightMedium: 600,
    fontWeightBold: 700,
    h1: {
      fontWeight: 800,
      lineHeight: 80 / 64,
      fontSize: pxToRem(40),
      ...responsiveFontSizes({ sm: 52, md: 58, lg: 64 }),
    },
    h2: {
      fontWeight: 800,
      lineHeight: 64 / 48,
      fontSize: pxToRem(32),
      ...responsiveFontSizes({ sm: 40, md: 44, lg: 48 }),
    },
    h3: {
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: pxToRem(24),
      ...responsiveFontSizes({ sm: 26, md: 30, lg: 32 }),
    },
    h4: {
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: pxToRem(20),
      ...responsiveFontSizes({ sm: 20, md: 24, lg: 24 }),
    },
    h5: {
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: pxToRem(18),
      ...responsiveFontSizes({ sm: 19, md: 20, lg: 20 }),
    },
    h6: {
      fontWeight: 700,
      lineHeight: 28 / 18,
      fontSize: pxToRem(17),
      ...responsiveFontSizes({ sm: 18, md: 18, lg: 18 }),
    },
    subtitle1: {
      fontWeight: 600,
      lineHeight: 1.5,
      fontSize: pxToRem(16),
    },
    subtitle2: {
      fontWeight: 600,
      lineHeight: 22 / 14,
      fontSize: pxToRem(14),
    },
    body1: {
      lineHeight: 1.5,
      fontSize: pxToRem(16),
    },
    body2: {
      lineHeight: 22 / 14,
      fontSize: pxToRem(14),
    },
    caption: {
      lineHeight: 1.5,
      fontSize: pxToRem(12),
    },
    overline: {
      fontWeight: 700,
      lineHeight: 1.5,
      fontSize: pxToRem(12),
      textTransform: 'uppercase',
    },
    button: {
      fontWeight: 700,
      lineHeight: 24 / 14,
      fontSize: pxToRem(14),
      textTransform: 'capitalize',
    },
  }) as const;

export default typography;
